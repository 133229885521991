/* lain lain */
.max-content {
  width: max-content !important;
}

.full_width {
  width: 100%;
}

.max-content-h {
  height: max-content !important;
}

/* border radius */

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-lg {
  border-radius: 8px !important;
}

.rounded-xl {
  border-radius: 24px !important;
}

.pill {
  border-radius: 30px !important;
}

.borbox {
  box-sizing: border-box !important;
}

/* padding */

/* padding all */
.pa-0 {
  padding: 0 !important;
}

.pa-1 {
  padding: 4px !important;
}

.pa-2 {
  padding: 8px !important;
}

.pa-3 {
  padding: 12px !important;
}

.pa-4 {
  padding: 16px !important;
}

.pa-5 {
  padding: 20px !important;
}

.pa-50 {
  padding: 50px !important;
}

.pa-100 {
  padding: 100px !important;
}

/* padding x */
.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

/* padding y */
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

/* padding top */
.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

/* padding bottom */
.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

/* padding right */
.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

/* padding left */
.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

/* margin */

/* margin all */
.ma-0 {
  margin: 0 !important;
}

.ma-1 {
  margin: 4px !important;
}

.ma-2 {
  margin: 8px !important;
}

.ma-3 {
  margin: 12px !important;
}

.ma-4 {
  margin: 16px !important;
}

.ma-5 {
  margin: 20px !important;
}

.ma-50 {
  margin: 50px !important;
}

.ma-100 {
  margin: 100px !important;
}

/* margin x */
.mx-1 {
  margin: 0 4px !important;
}

.mx-2 {
  margin: 0 8px !important;
}

.mx-3 {
  margin: 0 12px !important;
}

.mx-4 {
  margin: 0 16px !important;
}

.mx-5 {
  margin: 0 20px !important;
}

.mx-50 {
  margin: 0 50px !important;
}

.mx-100 {
  margin: 0 100px !important;
}

/* margin y */
.my-1 {
  margin: 4px 0 !important;
}

.my-2 {
  margin: 8px 0 !important;
}

.my-3 {
  margin: 12px 0 !important;
}

.my-4 {
  margin: 16px 0 !important;
}

.my-5 {
  margin: 20px 0 !important;
}

.my-50 {
  margin: 50px 0 !important;
}

.my-100 {
  margin: 100px 0 !important;
}

/* margin top */
.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

/* margin bottom */
.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

/* margin right */
.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

/* margin left */
.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

/* FLEX */

.d-flex {
  display: flex !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flexy {
  flex: 1;
}

.cursor_pointer {
  cursor: pointer;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.cursor_text {
  cursor: text;
}

/* font & typography */
.xsmall_txt {
  font-size: x-small;
}

.small_txt {
  font-size: small;
}

.px12_txt {
  font-size: small;
}

.medium_txt {
  font-size: medium;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.black-text {
  color: #000 !important;
}

.white-text {
  color: #fff !important;
}

.red-text {
  color: #f44336 !important;
}

.pink-text {
  color: #e91e63 !important;
}

.purple-text {
  color: #9c27b0 !important;
}

.deep-purple-text {
  color: #673ab7 !important;
}

.indigo-text {
  color: #3f51b5 !important;
}

.blue-text {
  color: #2196f3 !important;
}

.cyan-text {
  color: #00bcd4 !important;
}

.teal-text {
  color: #009688 !important;
}

.green-text {
  color: #4caf50 !important;
}

.light-green-text {
  color: #8bc34a !important;
}

.lime-text {
  color: #cddc39 !important;
}

.yellow-text {
  color: #ffeb3b !important;
}

.amber-text {
  color: #ffc107 !important;
}

.orange-text {
  color: #ff9800 !important;
}

.deep-orange-text {
  color: #ff5722 !important;
}

.brown-text {
  color: #795548 !important;
}

.grey-text {
  color: #9e9e9e !important;
}

.white-bg {
  background: #fff !important;
}

.red-bg {
  background: #f44336 !important;
}

.pink-bg {
  background: #e91e63 !important;
}

.purple-bg {
  background: #9c27b0 !important;
}

.deep-purple-bg {
  background: #673ab7 !important;
}

.indigo-bg {
  background: #3f51b5 !important;
}

.blue-bg {
  background: #2196f3 !important;
}

.cyan-bg {
  background: #00bcd4 !important;
}

.teal-bg {
  background: #009688 !important;
}

.green-bg {
  background: #4caf50 !important;
}

.light-green-bg {
  background: #8bc34a !important;
}

.lime-bg {
  background: #cddc39 !important;
}

.yellow-bg {
  background: #ffeb3b !important;
}

.amber-bg {
  background: #ffc107 !important;
}

.orange-bg {
  background: #ff9800 !important;
}

.deep-orange-bg {
  background: #ff5722 !important;
}

.brown-bg {
  background: #795548 !important;
}

.grey-bg {
  background: #9e9e9e !important;
}

.rounded-icon {
  width: 28px;
  height: 28px;
  border-radius: 14px;
}

.border-solid {
  border: 2px solid black;
}

.border-solid-light {
  border: 2px solid #d9d9d9;
}

.box-date {
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  margin: 2px;
}
