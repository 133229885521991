.container_auth {
    width: 100%;
    min-height: 100vh;
    background: #f2eff9;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_auth {
    width: 450px;
    min-height: 525px;
}


.inset_shadow {
    /* box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25); */
    box-shadow: inset 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    width: 100%;
    height: 230px;
    background: url('../img/bg_auth.png');
    background-size: cover;
    border-radius: 8px;
}

.label_auth {
    font-size: 14px;
    font-weight: 700;
    color: #320071;
}