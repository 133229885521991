.unpick {
    padding: 5px 10px;
    border: 1px solid #8AC7CF;
    border-radius: 3px;
    /* width: max-content; */
    cursor: pointer;
    transition: .5s;
    font-weight: 500;
    text-align: center;
}

.unpick:hover {
    background: #d8ebed;
    transition: .5s;
}

.pick {
    padding: 5px 10px;
    border: 1px solid #8AC7CF;
    border-radius: 3px;
    text-align: center;
    /* width: max-content; */
    cursor: pointer;
    transition: .5s;
    font-weight: 500;
}