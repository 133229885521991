@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.txt_menu {
  font-size: 13px;
}

.txt_table {
  font-size: 12px;
}


.text-primary-psy {
  color: #005765;
}

.title_nav {
  font-size: 24px;
  font-weight: 500;
}

.main {
  position: relative;
  width: 100%;
  display: flex;
}


.temp_notif {
  padding: 3px;
  border-radius: 50%;
  background: #fff;
  height: max-content;
  width: max-content;
}

nav {
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  /* position: fixed; */
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.page {
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}

.main_page {
  padding: 20px;
}

aside {
  position: relative;
  width: 270px;
  height: 100%;
}

.sidebar {
  width: 270px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  z-index: 99;
  top: 0;
  padding: 8px;
  padding-right: 3px;
}

.sidebar::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  /* background: #9CD3D7 !important; */
  border-radius: 10px !important;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.search_input {
  border: none;
  padding: 12px;
  flex: 1;
}

.search_input:focus {
  outline: none;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  font-size: small;
  font-weight: bold;
}



.temp-search-keyup {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.search-keyup {
  width: 100%;
  padding: 10px 30px 10px 10px;
  border-radius: 8px;
  border: none;
}

.search-keyup:focus {
  outline: none;
}

.basic-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
}

.basic-input:focus {
  outline: none;
}

.add-note {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.log_tgl {
  width: 170px;
  padding: 10px 0;
}

.line_log {
  flex: 1;
  padding: 1px;
}

.line_log_x {
  min-height: 100%;
  width: max-content;
  padding: 1px;
}

.timeline {
  min-height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.connector {
  padding: 1px;
  height: 100%;
  width: max-content;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.left_bor {
  /* border-right: 2px solid #8AC7CF; */
  min-height: 100%;
}

.container_chat {
  margin-top: -25px;
}

.typer_msg {
  resize: none;
  min-height: 30px;
  max-height: 100px;
}

.chatbox {
  border: 1px solid #ccc;
  resize: none;
  padding: 10px;
  /* min-height: 35px; */
  border-radius: 5px;

}

.chatbox:focus {
  outline: none;
}

.styled_scroll::-webkit-scrollbar {
  width: 5px !important;
}

.styled_scroll::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 10px !important;
}

.styled_scroll::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

.styled_scroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}


.temp_vid {
  width: 100%;
  height: 100%;
}

video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.my_menu {
  min-width: 'max-content';
  top: 25px !important;
}

.arrow_Class {
  left: 20px !important;
}

.btn_save_kode {
  padding: 10px;
  border: none;
  color: #005765;
}

.my_menu_full {
  min-width: 100% !important;
  top: 5px !important;
  border-radius: 8px !important;
}

.steper {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.select_sty {
  width: 100%;
  padding: 10px;
  /* border-radius: 8px; */
  border: none;
  box-sizing: border-box;
  flex: 1;
}

.select_sty:focus {
  outline: none;
  background: #fff !important;
}

.input_vcr {
  width: 100%;
  padding: 8px;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  flex: 1;
  margin-right: 5px;
}

.input_vcr:focus {
  outline: none;
  background: #fff !important;
}

.card_at {
  height: 500px;
  overflow-y: scroll;
}

.test {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background: #E8F6F8 !important;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.486);
}

.box_pemateri {
  padding: 6px;
  background: #DEDEDE;
  border-radius: 4px;
}

.label-cat {
  padding: 4px 5px;
  border-radius: 3px;
  max-width: 110px;
}

.steper_course {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}